import React, { useState } from 'react';
import Layout from './components/layout/NavbarLayout';
import BlogSite from './components/BlogSite';
import ArticlePage from './components/sections/ArticlePage';
import AllArticles from './components/sections/AllArticles';

function App() {
  const [currentPage, setCurrentPage] = useState('home');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    setCurrentPage('article');
    window.scrollTo(0, 0);
  };

  const handleBackClick = () => {
    setCurrentPage('home');
    setSelectedArticle(null);
    setSelectedCategory(null);
    window.scrollTo(0, 0);
  };

  const handleNavigationClick = (page, params = {}) => {
    if (params.category) {
      setSelectedCategory(params.category);
    }
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
  };

  const renderPage = () => {
    switch (currentPage) {
      case 'home':
        return (
          <BlogSite 
            isDarkMode={isDarkMode} 
            onArticleClick={handleArticleClick}
            onNavigate={handleNavigationClick}
          />
        );
      case 'article':
        return (
          <ArticlePage 
            isDarkMode={isDarkMode} 
            onBack={handleBackClick}
            article={selectedArticle}
          />
        );
      case 'articles':
        return (
          <AllArticles 
            isDarkMode={isDarkMode}
            onArticleClick={handleArticleClick}
            selectedCategory={selectedCategory}
            onCategorySelect={handleCategorySelect}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Layout 
      isDarkMode={isDarkMode}
      setIsDarkMode={setIsDarkMode}
      onNavigate={handleNavigationClick}
      currentPage={currentPage}
    >
      {renderPage()}
    </Layout>
  );
}

export default App;