import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { blogPosts } from '../../data/blogData';
import Pagination from '../layout/Pagination';

const AllArticles = ({ isDarkMode, onArticleClick, selectedCategory, onCategorySelect }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5; // Number of posts to show per page

  // Filter and sort posts
  const filteredPosts = selectedCategory
    ? blogPosts.filter(post => post.category === selectedCategory)
    : blogPosts;

  const sortedPosts = [...filteredPosts].sort((a, b) => new Date(b.date) - new Date(a.date));

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = sortedPosts.slice(indexOfFirstPost, indexOfLastPost);

  // Get unique categories
  const categories = [...new Set(blogPosts.map(post => post.category))].sort();

  // Calculate total pages
  const totalPages = Math.ceil(sortedPosts.length / postsPerPage);

  // Reset to first page when category changes
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCategory]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold">
          {selectedCategory ? `${selectedCategory} Articles` : 'All Articles'}
        </h1>
        <p className={`mt-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
          {sortedPosts.length} article{sortedPosts.length !== 1 ? 's' : ''}
          {selectedCategory ? ` in ${selectedCategory}` : ''}
        </p>

        {/* Category filter chips */}
        <div className="flex flex-wrap gap-2 mt-6">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => onCategorySelect(category)}
              className={`px-4 py-2 rounded-full text-sm ${
                category === selectedCategory
                  ? 'bg-blue-600 text-white'
                  : isDarkMode
                  ? 'bg-gray-800 text-gray-200 hover:bg-gray-700'
                  : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
              }`}
            >
              {category}
              {category === selectedCategory && (
                <X size={14} className="inline-block ml-2" />
              )}
            </button>
          ))}
        </div>
      </div>

      {/* Articles Grid */}
      <div className="grid gap-8">
        {currentPosts.map((post) => (
          <article key={post.id} className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
            <div className="flex flex-wrap items-center gap-2 text-sm text-gray-500 mb-2">
              <span 
                className={`px-2 py-1 rounded ${
                  isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                }`}
              >
                {post.category}
              </span>
              <span>•</span>
              <span>{post.date}</span>
              <span>•</span>
              <span>{post.readTime}</span>
            </div>
            <h2 className="text-xl font-bold mb-2">{post.title}</h2>
            <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>{post.excerpt}</p>
            <div className="flex flex-wrap items-center justify-between gap-4">
              <span className="text-sm text-gray-500">By {post.author}</span>
              <button 
                onClick={() => onArticleClick(post)}
                className="text-blue-600 hover:underline cursor-pointer"
              >
                Read more →
              </button>
            </div>
          </article>
        ))}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="mt-8">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isDarkMode={isDarkMode}
          />
        </div>
      )}
    </div>
  );
};

export default AllArticles;