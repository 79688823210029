import React from 'react';
import { Menu, Search, Sun, Moon } from 'lucide-react';
import { blogPosts } from '../data/blogData';

const BlogSite = ({ isDarkMode, setIsDarkMode, onArticleClick, onNavigate }) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    // Get the first 3 main posts
    const mainPosts = [...blogPosts]
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 3);

    // Get the first x recent posts
    const recentPosts = [...blogPosts]
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 5);

    // Dynamically count categories from blogPosts
    const categories = blogPosts.reduce((acc, post) => {
        acc[post.category] = (acc[post.category] || 0) + 1;
        return acc;
    }, {});

    // Convert categories object to array and sort alphabetically
    const categoriesList = Object.entries(categories)
        .map(([name, count]) => ({ name, count }))
        .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className={`min-h-screen w-full overflow-x-hidden ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-800'}`}>
      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-8">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Blog Posts - Limited to x */}
          <div className="md:col-span-2 space-y-8">
            {mainPosts.map((post) => (
              <article key={post.id} className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
                <div className="flex flex-wrap items-center gap-2 text-sm text-gray-500 mb-2">
                  <span>{post.category}</span>
                  <span>•</span>
                  <span>{post.date}</span>
                </div>
                <h2 className="text-xl font-bold mb-2">{post.title}</h2>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>{post.excerpt}</p>
                <div className="flex flex-wrap items-center justify-between gap-4">
                  <span className="text-sm text-gray-500">By {post.author}</span>
                  <button 
                    onClick={() => onArticleClick(post)}
                    className="text-blue-600 hover:underline cursor-pointer"
                  >
                    Read more →
                  </button>
                </div>
              </article>
            ))}
          </div>

          {/* Sidebar */}
          <aside className="space-y-8">
            {/* Categories */}
            <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
                <h3 className="text-lg font-bold mb-4">Categories</h3>
                <ul className="space-y-2">
                    {categoriesList.map((category) => (
                    <li key={category.name}>
                        <button 
                        onClick={() => onNavigate('articles', { category: category.name })}
                        className="hover:text-blue-600 text-left w-full"
                        >
                        {category.name} ({category.count})
                        </button>
                    </li>
                    ))}
                </ul>
            </div>

            {/* Recent Posts */}
            <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
                <h3 className="text-lg font-bold mb-4">Recent Posts</h3>
                <ul className="space-y-4">
                    {recentPosts.map((post) => (
                    <li key={post.id}>
                        <button 
                        onClick={() => onArticleClick(post)} 
                        className="block text-left w-full hover:text-blue-600"
                        >
                        <h4 className="font-medium">{post.title}</h4>
                        <span className="text-sm text-gray-500">{post.date}</span>
                        </button>
                    </li>
                    ))}
                </ul>
            </div>
      </aside>
        </div>
      </main>

      {/* Footer */}
      <footer className={`border-t ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
        <div className="max-w-7xl mx-auto px-4 py-8">
          <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
            <div>
              <h4 className="font-bold mb-4">About DevBlog</h4>
              <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                A community of developers sharing knowledge and experiences.
              </p>
            </div>
            <div>
              <h4 className="font-bold mb-4">Quick Links</h4>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-blue-600">Home</a></li>
                <li><a href="#" className="hover:text-blue-600">About</a></li>
                <li><a href="#" className="hover:text-blue-600">Contact</a></li>
              </ul>
            </div>
            <div>
              <h4 className="font-bold mb-4">Categories</h4>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-blue-600">Technology</a></li>
                <li><a href="#" className="hover:text-blue-600">Design</a></li>
                <li><a href="#" className="hover:text-blue-600">Development</a></li>
              </ul>
            </div>
            <div>
              <h4 className="font-bold mb-4">Newsletter</h4>
              <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                Subscribe to our newsletter for updates.
              </p>
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2"
              />
              <button className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
                Subscribe
              </button>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t text-center text-gray-500">
            <p>© 2024 DevBlog. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default BlogSite;