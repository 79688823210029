import React from 'react';
import { ArrowLeft, Clock, User, Tag } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const ArticlePage = ({ isDarkMode, onBack, article }) => {
  if (!article) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>Article not found</p>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-800'}`}>
      {/* Article Header */}
      <header className="border-b">
        <div className="max-w-4xl mx-auto px-4 py-8">
          
          <h1 className="text-3xl md:text-4xl font-bold mb-4">{article.title}</h1>
          
          <div className="flex flex-wrap items-center gap-4 text-gray-500">
            <div className="flex items-center gap-2">
              <User size={18} />
              <span>{article.author}</span>
            </div>
            <div className="flex items-center gap-2">
              <Clock size={18} />
              <span>{article.readTime}</span>
            </div>
            <div className="flex items-center gap-2">
              <Tag size={18} />
              <span>{article.category}</span>
            </div>
          </div>
        </div>
      </header>

      {/* Article Content */}
      <main className="max-w-4xl mx-auto px-4 py-8">
        <article className={`prose prose-lg mx-auto ${
          isDarkMode ? 'prose-invert' : ''
        } max-w-none`}>
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]}
            components={{
              h2: ({node, ...props}) => <h2 className="text-2xl font-bold mt-8 mb-4" {...props} />,
              h3: ({node, ...props}) => <h3 className="text-xl font-bold mt-6 mb-3" {...props} />,
              p: ({node, ...props}) => <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`} {...props} />,
              ul: ({node, ...props}) => <ul className="list-disc pl-6 mb-4" {...props} />,
              ol: ({node, ...props}) => <ol className="list-decimal pl-6 mb-4" {...props} />,
              li: ({node, ...props}) => <li className="mb-2" {...props} />,
              code: ({node, inline, ...props}) => 
                inline ? (
                  <code className={`px-1 py-0.5 rounded ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`} {...props} />
                ) : (
                  <code className={`block p-4 rounded-lg my-4 font-mono text-sm ${
                    isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
                  }`} {...props} />
                ),
              pre: ({node, ...props}) => (
                <pre className={`p-4 rounded-lg my-4 overflow-x-auto ${
                  isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
                }`} {...props} />
              ),
              strong: ({node, ...props}) => <strong className="font-bold" {...props} />,
              em: ({node, ...props}) => <em className="italic" {...props} />,
              blockquote: ({node, ...props}) => (
                <blockquote className={`border-l-4 pl-4 italic my-4 ${
                  isDarkMode ? 'border-gray-600 text-gray-400' : 'border-gray-300 text-gray-600'
                }`} {...props} />
              ),
            }}
          >
            {article.content}
          </ReactMarkdown>
        </article>
      </main>

      {/* Article Footer */}
      <footer className={`border-t mt-16 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
        <div className="max-w-4xl mx-auto px-4 py-8">
          <div className="flex flex-col items-center text-center">
            <h3 className="text-xl font-bold mb-4">Share this article</h3>
            <div className="flex space-x-4">
              <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
                Twitter
              </button>
              <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
                LinkedIn
              </button>
              <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
                Facebook
              </button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ArticlePage;