import React from 'react';
import { Menu, Search, Sun, Moon } from 'lucide-react';

const Layout = ({ children, isDarkMode, setIsDarkMode, onNavigate, currentPage }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <div className={`min-h-screen w-full overflow-x-hidden ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-800'}`}>
      {/* Header */}
      <header className={`border-b shadow-sm sticky top-0 z-50 ${
        isDarkMode ? 'bg-gray-900 border-gray-700' : 'bg-white border-gray-200'
      }`}>
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className={`md:hidden p-2 rounded-lg ${
                  isDarkMode 
                    ? 'hover:bg-gray-800' 
                    : 'hover:bg-gray-100'
                }`}
              >
                <Menu size={24} />
              </button>
              <button 
                onClick={() => onNavigate('home')}
                className="text-2xl font-bold hover:text-blue-600"
              >
                DevBlog
              </button>
            </div>
            
            <div className="hidden md:flex items-center space-x-6">
              <nav>
                <ul className="flex space-x-6">
                  <li>
                    <button 
                      onClick={() => onNavigate('home')}
                      className={`hover:text-blue-600 ${currentPage === 'home' ? 'text-blue-600' : ''}`}
                    >
                      Home
                    </button>
                  </li>
                  <li>
                    <button 
                      onClick={() => onNavigate('articles')}
                      className={`hover:text-blue-600 ${currentPage === 'articles' ? 'text-blue-600' : ''}`}
                    >
                      Articles
                    </button>
                  </li>
                  <li>
                    <button 
                      onClick={() => onNavigate('about')}
                      className={`hover:text-blue-600 ${currentPage === 'about' ? 'text-blue-600' : ''}`}
                    >
                      About
                    </button>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="flex items-center space-x-4">
              <button
                onClick={() => setIsDarkMode(!isDarkMode)}
                className={`p-2 rounded-lg ${
                  isDarkMode 
                    ? 'hover:bg-gray-800' 
                    : 'hover:bg-gray-100'
                }`}
              >
                {isDarkMode ? <Sun size={24} /> : <Moon size={24} />}
              </button>
              <div className="relative hidden sm:block">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type="text"
                  placeholder="Search..."
                  className={`pl-10 pr-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    isDarkMode 
                      ? 'bg-gray-800 border-gray-700 text-white' 
                      : 'bg-white border-gray-200 text-gray-800'
                  } border`}
                />
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className={`md:hidden border-b shadow-sm fixed w-full z-40 ${
          isDarkMode 
            ? 'bg-gray-900 border-gray-700' 
            : 'bg-white border-gray-200'
        }`}>
          <nav className="max-w-7xl mx-auto px-4 py-4">
            <ul className="space-y-4">
              <li>
                <button 
                  onClick={() => {
                    onNavigate('home');
                    setIsMenuOpen(false);
                  }}
                  className={`block w-full text-left py-2 px-3 rounded-lg ${
                    currentPage === 'home'
                      ? 'text-blue-600'
                      : isDarkMode
                      ? 'hover:bg-gray-800'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  Home
                </button>
              </li>
              <li>
                <button 
                  onClick={() => {
                    onNavigate('articles');
                    setIsMenuOpen(false);
                  }}
                  className={`block w-full text-left py-2 px-3 rounded-lg ${
                    currentPage === 'articles'
                      ? 'text-blue-600'
                      : isDarkMode
                      ? 'hover:bg-gray-800'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  Articles
                </button>
              </li>
              <li>
                <button 
                  onClick={() => {
                    onNavigate('categories');
                    setIsMenuOpen(false);
                  }}
                  className={`block w-full text-left py-2 px-3 rounded-lg ${
                    currentPage === 'categories'
                      ? 'text-blue-600'
                      : isDarkMode
                      ? 'hover:bg-gray-800'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  Categories
                </button>
              </li>
              <li>
                <button 
                  onClick={() => {
                    onNavigate('about');
                    setIsMenuOpen(false);
                  }}
                  className={`block w-full text-left py-2 px-3 rounded-lg ${
                    currentPage === 'about'
                      ? 'text-blue-600'
                      : isDarkMode
                      ? 'hover:bg-gray-800'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  About
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}

      {/* Main Content */}
      <div className="pt-4">
        {children}
      </div>
    </div>
  );
};

export default Layout;